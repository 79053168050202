import * as React from "react";
import Layout from "../../../components/Layout";

const Page = () => {
  return (
    <Layout pagePath="/protesto-formos/budinkite-veikti/ambasada/"></Layout>
  );
};

export default Page;
